import {
  addDays,
  addMinutes,
  fromUnixTime,
  getDayOfYear,
  getMinutes,
  isFuture,
  isThisYear,
  isToday,
  isTomorrow,
  secondsInDay,
  secondsToHours,
  secondsToMinutes,
} from 'date-fns';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  getUnixTime,
  isSameYear,
  parseJSON,
} from 'date-fns/fp';
import format from 'date-fns/fp/format';
import isBefore from 'date-fns/fp/isBefore';
import parseISO from 'date-fns/fp/parseISO';
import {
  T,
  complement,
  compose,
  cond,
  curry,
  equals,
  gt,
  gte,
  isNil,
  lt,
  when,
} from 'ramda';
import { P, match } from 'ts-pattern';

import { DURATION_UNITS } from 'copy/duration';

import { getDurationBreakdown } from 'utils/duration';
import { isString, notEmptyOrNil } from 'utils/helpers';
import { pluralizeWord } from 'utils/strings';
import { mapSecondsToDurationBreakdown } from 'utils/time';

import { DurationBreakdown, DurationOptions } from 'types/duration';

export const parseUnixTimestamp = compose<number, number, number>(
  // multiply by 1000 as timestamps come through in seconds
  (date) => date * 1000,
  Number
);

export const formatDuration = (options: DurationOptions): string => {
  const { endDate, startDate } = options;

  const { days, hours, minutes } = getDurationBreakdown({
    endDate,
    startDate,
  });

  return formatDurationBreakdown({ days, hours, minutes });
};

// TODO: Add a test for this function
export const formatDurationFromSeconds = (seconds: number): string => {
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  // When there is exactly 1 day, show 24h
  if (days === 1 && hours === 0 && minutes === 0) {
    return '24h';
  }

  // When there are only days to show, spell out days
  if (days > 1 && hours === 0 && minutes === 0) {
    return `${days} ${pluralizeWord('day', days)}`;
  }

  return formatDurationBreakdown({ days, hours, minutes });
};

export const postedOn = compose(format('d MMMM yyyy'), parseISO);

export const lastUpdated = compose(format('MMMM d, yyyy'), parseISO);

const formatDetailed = format(`MMM d, yyyy 'at' h:mmaaa`);

export const getDetailedTime = (timestamp: string) => {
  const date = parseJSON(timestamp);
  return formatDetailed(date);
};

export const getHourMinute = (timestamp: string) => {
  const date = parseJSON(timestamp);
  return format('h:mmaaa')(date);
};

export const getDay = (timestamp: string) => {
  const date = parseJSON(timestamp);
  return format('dd')(date);
};

export const getMonth = (timestamp: string) => {
  const date = parseJSON(timestamp);
  return format('MMM')(date);
};

// inverts the isNil check
const notNil = complement(isNil);

// Convert a string to a number, get a js date
// from unix time in seconds, then compare it with
// the current js date
export const isUnixDateInPast = (dateUnix: number): boolean => {
  const nowUnix = Date.now();
  const isBeforeNow = compose(isBefore(nowUnix), parseUnixTimestamp);

  const result = when(notNil, isBeforeNow, dateUnix);

  return result;
};

export function formatRelativeTimestamp(
  timestamp: string,
  options: {
    /**
     * Optional prefix for absolute future dates
     * @example "Starts on" → "Starts on Dec 12"
     * */
    prefixFutureDate?: string;
    /**
     * Optional prefix for relative future dates
     * @example "Launches in" → "Launches in 24m"
     * */
    prefixFutureRelative?: string;
    /**
     * Optional prefix for absolute historic dates
     * @example "Ended on" → "Ended on Dec 12"
     * */
    prefixPastDate?: string;
    /**
     * Optional prefix for relative historic dates
     * Typically works best with `shouldSuffixRelativeTimeAgo` set to true
     * @example "Updated" → "Updated 24m ago"
     * @example "Updated" → "Updated 24m"
     * */
    prefixPastRelative?: string;
    /**
     * Set to true to add "ago" to relative dates
     *
     * @example true → "4h ago"
     * @example false → "4h"
     * */
    shouldSuffixRelativeTimeAgo?: boolean;
  } = {
    shouldSuffixRelativeTimeAgo: false,
  }
): string {
  const date = parseJSON(timestamp);
  const now = new Date();
  const diffInSeconds = differenceInSeconds(now, date);
  const absoluteDiffInSeconds = Math.abs(diffInSeconds);
  const isPast = diffInSeconds < 0;

  const enhanceOptions = {
    ...options,
    isPast,
  };

  const enhanceRelative = (time: string): string => {
    return match(enhanceOptions)
      .with(
        {
          isPast: true,
          prefixPastRelative: P.select('prefix', P.string),
          shouldSuffixRelativeTimeAgo: false,
        },
        ({ prefix }) => `${prefix} ${time}`
      )
      .with(
        {
          isPast: true,
          prefixPastRelative: P.select('prefix', P.string),
          shouldSuffixRelativeTimeAgo: true,
        },
        ({ prefix }) => `${prefix} ${time} ago`
      )
      .with(
        { isPast: true, shouldSuffixRelativeTimeAgo: true },
        () => `${time} ago`
      )
      .with(
        { isPast: false, prefixFutureRelative: P.select('prefix', P.string) },
        ({ prefix }) => `${prefix} ${time}`
      )
      .otherwise(() => time);
  };

  const enhanceDate = (formattedDate: string): string => {
    return match(enhanceOptions)
      .with(
        { isPast: false, prefixFutureDate: P.select('prefix', P.string) },
        ({ prefix }) => `${prefix} ${formattedDate}`
      )
      .with(
        { isPast: true, prefixPastDate: P.select('prefix', P.string) },
        ({ prefix }) => `${prefix} ${formattedDate}`
      )
      .otherwise(() => formattedDate);
  };

  return match(absoluteDiffInSeconds)
    .when(gte(60), () => {
      return enhanceRelative('1m');
    })
    .when(gt(3600), (seconds) => {
      return enhanceRelative(`${secondsToMinutes(seconds)}m`);
    })
    .when(gt(secondsInDay), (seconds) => {
      return enhanceRelative(`${secondsToHours(seconds)}h`);
    })
    .otherwise(() => {
      return enhanceDate(formatDateAsDayOfMonth(date));
    });
}

export const parseDateToUnix: (
  arg0: string | Date | null | undefined
) => number = when(
  notEmptyOrNil,
  compose<string, Date, number>(getUnixTime, parseJSON)
);

export const formatBidDateShort = when(
  isString,
  compose(
    // Jan 22, 2021 at 2:41p.m.
    (date) => provenanceDateFormat(date),
    // parse the date from json
    parseJSON,
    // Add Z to convert this into a UTC timezone timestamp, parseISO then treats it as such
    (date: string) => `${date}Z`
  )
);

export const provenanceDateFormat = format(`MMM d, yyyy 'at' h:mmaaa`);

const timeUnits = {
  // 1 year in mins
  year: 525600,
  // 1 month in mins
  month: 43800,
  // 1 day in mins
  day: 1440,
  // 1 hour in mins
  hour: 60,
  // otherwise format as mins
  minute: 1,
};

type TimeFormat = 'long' | 'short';

const pluralizeTimeAgo = (
  timeUnit: string,
  format: TimeFormat,
  timeAgo: number
) => {
  const isAbbr = format === 'short';

  if (isAbbr) {
    return timeAgo + timeUnit;
  }

  return timeAgo === 1
    ? `${timeAgo} ${timeUnit} ago`
    : `${timeAgo} ${timeUnit}s ago`;
};

const calculateTimeDifference = curry(
  (timeUnit: number, format: TimeFormat, diffInMins: number) => {
    const timeAgo = Math.round(diffInMins / timeUnit);

    const isAbbr = format === 'short';

    return cond<number, string>([
      [
        equals(timeUnits['year']),
        () => pluralizeTimeAgo(isAbbr ? 'y' : 'year', format, timeAgo),
      ],
      [
        equals(timeUnits['month']),
        () => pluralizeTimeAgo(isAbbr ? 'mo' : 'month', format, timeAgo),
      ],
      [
        equals(timeUnits['day']),
        () => pluralizeTimeAgo(isAbbr ? 'd' : 'day', format, timeAgo),
      ],
      [
        equals(timeUnits['hour']),
        () => pluralizeTimeAgo(isAbbr ? 'h' : 'hour', format, timeAgo),
      ],
      [
        equals(timeUnits['minute']),
        () => pluralizeTimeAgo(isAbbr ? 'm' : 'minute', format, timeAgo),
      ],
    ])(timeUnit);
  }
);

export function timeAgoInWords(timestamp: string, format: TimeFormat): string {
  const unixDate = parseDateToUnix(timestamp);
  const minsAgo = differenceInMinutes(unixDate * 1000, Date.now());

  return cond([
    [lt(timeUnits['year']), calculateTimeDifference(timeUnits['year'], format)],
    [
      lt(timeUnits['month']),
      calculateTimeDifference(timeUnits['month'], format),
    ],
    [lt(timeUnits['day']), calculateTimeDifference(timeUnits['day'], format)],
    [lt(timeUnits['hour']), calculateTimeDifference(timeUnits['hour'], format)],
    [T, calculateTimeDifference(timeUnits['minute'], format)],
  ])(minsAgo);
}

export function timeRemainingInWords(
  timestamp: string,
  format: TimeFormat
): string {
  const unixDate = parseDateToUnix(timestamp);
  const minsAgo = differenceInMinutes(Date.now(), unixDate * 1000);

  return cond([
    [lt(timeUnits['year']), calculateTimeDifference(timeUnits['year'], format)],
    [
      lt(timeUnits['month']),
      calculateTimeDifference(timeUnits['month'], format),
    ],
    [lt(timeUnits['day']), calculateTimeDifference(timeUnits['day'], format)],
    [lt(timeUnits['hour']), calculateTimeDifference(timeUnits['hour'], format)],
    [T, calculateTimeDifference(timeUnits['minute'], format)],
  ])(minsAgo);
}

export const getExpectedUploadTime = (timeInSeconds: number) => {
  if (Math.floor(timeInSeconds) === 0) {
    return 'finishing soon';
  } else if (timeInSeconds > 3600) {
    const hours = Math.round(timeInSeconds / 3600);
    return `~${hours} ${pluralizeWord('hour', hours)}`;
  } else if (timeInSeconds > 60) {
    const mins = Math.round(timeInSeconds / 60);
    return `~${mins} ${pluralizeWord('min', mins)}`;
  } else {
    const seconds = Math.round(timeInSeconds);
    return `~${seconds} ${pluralizeWord('second', seconds)}`;
  }
};

export const formatRelativeDateTime = (date: string | Date): string => {
  const nowUnix = Date.now();
  const parsedDate = parseJSON(date);

  const diffInMins = differenceInMinutes(parsedDate, nowUnix);
  if (diffInMins < 1) {
    return 'Just now';
  }
  if (diffInMins < 60) {
    return `${diffInMins}m ago`;
  }

  const diffInHours = differenceInHours(parsedDate, nowUnix);
  if (diffInHours < 24) {
    return `${diffInHours}h ago`;
  }

  const diffInDays = differenceInDays(parsedDate, nowUnix);
  if (diffInDays < 8) {
    return `${diffInDays}d ago`;
  }

  if (isSameYear(parsedDate, nowUnix)) {
    return format('MMM d')(parsedDate);
  }

  return format('MMM d, yyyy')(parsedDate);
};

/** @example Dec 12 */
export const formatDateAsDayOfMonth = format('MMM d');

/** @example 10:24 AM */
const formatDateAsStartTime = format('h:mm a');

/** @example Fri, 12:00 PM */
const formatDateAsWeekdayAndTime = format('EEE, h:mm a');

/** @example Dec 1, 10:24 AM */
export const formatDateAsDateAndTime = format('MMM d, h:mm a');

/** @example 2023-12-20 08:56:52 */
export const formatDateAndTimeForDateTimeAttribute = format(
  'yyyy-MM-dd HH:mm:ss'
);

export function formatDateTime(
  date: Date,
  options: {
    alwaysShowTime?: boolean;
  } = {}
): string {
  const { alwaysShowTime = false } = options;

  const now = Date.now();

  if (isToday(date)) {
    return `Today, ${formatDateAsStartTime(date)}`;
  }

  if (isTomorrow(date)) {
    return `Tomorrow, ${formatDateAsStartTime(date)}`;
  }

  if (isBefore(addDays(now, 7), date)) {
    return formatDateAsWeekdayAndTime(date);
  }

  if (alwaysShowTime) {
    return formatDateAsDateAndTime(date);
  }

  return formatDateAsDayOfMonth(date);
}

/**
 * @see https://stackoverflow.com/a/67026057/1837427
 */
export const getTimeZoneAbbr = () =>
  new Date()
    .toLocaleTimeString('en-US', { timeZoneName: 'short' })
    .split(' ')[2];

export const isUpcoming = (isoDate: string | null): isoDate is string => {
  if (!isoDate) return false;
  return isFuture(parseJSON(isoDate));
};

export const getScheduledTime = (unix: number) => {
  if (unix === 0) return 'Now';

  const formattedTime = formatDetailed(fromUnixTime(unix));

  if (getTimeZoneAbbr()) {
    return `${formattedTime} ${getTimeZoneAbbr()}`;
  } else {
    return formattedTime;
  }
};

export const dateTimeToUnix = (datetime: string) => {
  return getUnixTime(parseJSON(datetime));
};

export function formatSecondsAsDurationBreakdown(seconds: number) {
  const durationBreakdown = mapSecondsToDurationBreakdown(seconds);
  return formatDurationBreakdown(durationBreakdown);
}

export function formatDurationBreakdown(duration: DurationBreakdown): string {
  const pairs = [
    { value: duration.days, unit: DURATION_UNITS.days },
    { value: duration.hours, unit: DURATION_UNITS.hours },
    { value: duration.minutes, unit: DURATION_UNITS.minutes },
  ];

  return pairs
    .filter((pair) => pair.value > 0)
    .map((pair) => `${pair.value}${pair.unit}`)
    .join(' ');
}

export function formatAbsoluteDateTime(
  date: Date,
  options: { abbreviate: boolean } = { abbreviate: true }
): string {
  if (isThisYear(date)) {
    return options.abbreviate
      ? format('MMM d, h:mmaaa')(date)
      : format('MMMM d, h:mmaaa')(date);
  }

  return options.abbreviate
    ? format('MMM d, yyyy, h:mmaaa')(date)
    : format('MMMM d, yyyy, h:mmaaa')(date);
}

type UnixTimestamp = number;

/**
 * @returns the transaction deadline time in unix seconds
 */
export const getUnixTxDeadline = (): UnixTimestamp => {
  return getUnixTime(addMinutes(Date.now(), 30));
};

export const formatDatePickerInputDate = (date: Date) => {
  const formattedDate = format('MMMM do, yyyy — h:mm aa', date);

  if (getTimeZoneAbbr()) {
    return `${formattedDate} ${getTimeZoneAbbr()}`;
  } else {
    return formattedDate;
  }
};

export const mapMomentStartsAtUnixToIso = (startsAtUnix: number): string => {
  // TODO: (moment-based-take-rates) parse to date string without timezone
  return startsAtUnix === 0
    ? new Date().toISOString().replace('Z', '')
    : fromUnixTime(startsAtUnix).toISOString().replace('Z', '');
};

const timeZoneMap = {
  PT: 'America/Los_Angeles',
  ET: 'America/New_York',
  GMT: 'Europe/London',
} as const;

export type TimeZoneAbbr = keyof typeof timeZoneMap;

type FormattedTime = `${string} ${TimeZoneAbbr}`;

export function format24TimeWithTimeZone(options: {
  date: Date;
  timeZone: TimeZoneAbbr;
}): FormattedTime {
  // Define options for formatting the date with minutes.
  const withMinutesOptions: Intl.DateTimeFormatOptions = {
    timeZone: timeZoneMap[options.timeZone], // The target time zone for formatting.
    hour: 'numeric', // Display hour in numeric format.
    minute: '2-digit', // Display minute in 2-digit format.
    hour12: false, // Use 24-hour clock format.
  };

  // Format the time including minutes.
  const formattedTime = new Intl.DateTimeFormat(
    'en-US', // Locale to use (US English).
    withMinutesOptions // Apply the defined options for formatting with minutes.
  ).format(options.date);

  // Return the formatted time string with the time zone abbreviation.
  return `${formattedTime} ${options.timeZone}`;
}

/**
 * Formats a date to a specified time zone's time, omitting minutes if they are zero.
 *
 * @param {Object} options - Configuration options for time formatting.
 * @param {Date} options.date - The date to format.
 * @param {TimeZoneAbbr} options.timeZone - The abbreviation of the target time zone.
 * @returns {FormattedTime} The time formatted as a string, with the time zone abbreviation appended.
 */
export function formatTimeWithTimeZone(options: {
  date: Date;
  timeZone: TimeZoneAbbr;
}): FormattedTime {
  // Define options for formatting the date with minutes.
  const withMinutesOptions: Intl.DateTimeFormatOptions = {
    timeZone: timeZoneMap[options.timeZone], // The target time zone for formatting.
    hour: 'numeric', // Display hour in numeric format.
    minute: '2-digit', // Display minute in 2-digit format.
    hour12: true, // Use 12-hour clock format.
  };

  // Define options for formatting the date without minutes.
  const withoutMinutesOptions: Intl.DateTimeFormatOptions = {
    timeZone: timeZoneMap[options.timeZone], // The target time zone for formatting.
    hour: 'numeric', // Display hour in numeric format.
    hour12: true, // Use 12-hour clock format.
  };

  // Format the time including minutes.
  const formattedTimeWithMinutes = new Intl.DateTimeFormat(
    'en-US', // Locale to use (US English).
    withMinutesOptions // Apply the defined options for formatting with minutes.
  ).format(options.date);

  // Check if the minute part of the time is zero and format accordingly.
  const formattedTime =
    getMinutes(options.date) === 0
      ? new Intl.DateTimeFormat('en-US', withoutMinutesOptions).format(
          options.date
        ) // Format without minutes if they are zero.
      : formattedTimeWithMinutes; // Otherwise, keep the format with minutes.

  // Return the formatted time string with the time zone abbreviation.
  return `${formattedTime} ${options.timeZone}`;
}

export function formatDateWithTimeZone(options: {
  date: Date;
  timeZone: TimeZoneAbbr;
}): string {
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    timeZone: timeZoneMap[options.timeZone],
    month: 'long',
    day: '2-digit',
  }).format(options.date);

  return formattedDate;
}

export function getDateOffset(options: {
  date: Date;
  timeZone: TimeZoneAbbr;
  baseTimeZone: TimeZoneAbbr;
}): number {
  const baseTime = new Date(
    options.date.toLocaleString('en-US', {
      timeZone: timeZoneMap[options.baseTimeZone],
    })
  );
  const targetTime = new Date(
    options.date.toLocaleString('en-US', {
      timeZone: timeZoneMap[options.timeZone],
    })
  );

  return getDayOfYear(targetTime) - getDayOfYear(baseTime);
}

export const isAuctionEndTimeInFuture = (options: { endTime: bigint }) => {
  const unixTimeStamp = Number(options.endTime);
  const parsedDate = fromUnixTime(unixTimeStamp);
  return isFuture(parsedDate);
};

export const isAuctionWithinEndedThreshold = (date: string) => {
  const parsedDate = parseJSON(date);
  const endTimeAgoInMins = differenceInMinutes(parsedDate, Date.now());
  return endTimeAgoInMins >= -5 && endTimeAgoInMins <= 0;
};
