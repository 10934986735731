import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns/fp';

import { DURATION_UNITS } from 'copy/duration';

import {
  CountdownBreakdown,
  CountdownMaxUnits,
  DurationBreakdown,
  DurationOptions,
} from 'types/duration';

import { padNumber } from './helpers';

export function padDays(days: number): string {
  return padNumber(days, 1);
}

export function padTime(time: number): string {
  return padNumber(time, 2);
}

/**
 * Returns a duration, broken down into days, hours, and minutes.
 * Designed to be used to describe the full length of a duration (i.e. "Will last 7 days")
 */
export const getDurationBreakdown = (
  options: DurationOptions
): DurationBreakdown => {
  const { startDate, endDate } = options;

  const days = differenceInDays(startDate, endDate);
  const hours = differenceInHours(startDate, endDate) % 24;
  const minutes = differenceInMinutes(startDate, endDate) % 60;

  return { days, hours, minutes };
};

/**
 * Returns a duration, broken down into days, hours, minutes, and seconds.
 * Designed to be used for countdowns (i.e. "7h 30m 15s remaining")
 */
export const getDurationCountdownBreakdown = (
  options: DurationOptions
): CountdownBreakdown => {
  const { startDate, endDate } = options;

  const { days, hours, minutes } = getDurationBreakdown(options);
  const seconds = differenceInSeconds(startDate, endDate) % 60;

  return { days, hours, minutes, seconds };
};

type DurationValue = number | string;

const formatDurationUnit = (
  value: DurationValue,
  unit: keyof typeof DURATION_UNITS
) => {
  return `${value}${DURATION_UNITS[unit]}`;
};

const formatDays = (days: DurationValue) => {
  return formatDurationUnit(days, 'days');
};

const formatHours = (hours: DurationValue) => {
  return formatDurationUnit(hours, 'hours');
};

const formatMinutes = (minutes: DurationValue) => {
  return formatDurationUnit(minutes, 'minutes');
};

const formatSeconds = (seconds: DurationValue) => {
  return formatDurationUnit(seconds, 'seconds');
};

export function formatCountdownBreakdown(
  breakdown: CountdownBreakdown,
  options: {
    maxUnits: CountdownMaxUnits;
  }
) {
  const { maxUnits } = options;
  const { days, hours, minutes, seconds } = breakdown;

  if (days > 0) {
    const formattedDays = formatDays(days);
    const formattedHours = formatHours(hours);

    if (maxUnits === 2) {
      return [formattedDays, formattedHours].join(' ');
    } else {
      return [formattedDays, formattedHours, formatMinutes(minutes)].join(' ');
    }
  }

  if (hours > 0) {
    const formattedHours = formatHours(hours);
    const formattedMinutes = formatMinutes(padTime(minutes));

    if (maxUnits === 2) {
      return [formattedHours, formattedMinutes].join(' ');
    } else {
      return [
        formattedHours,
        formattedMinutes,
        formatSeconds(padTime(seconds)),
      ].join(' ');
    }
  }

  if (minutes > 0) {
    // Adds padding to seconds when minutes + seconds
    return `${formatMinutes(minutes)} ${formatSeconds(padTime(seconds))}`;
  }

  if (seconds > 0) {
    return `${formatSeconds(seconds)}`;
  }

  // It's assumed that we validate if the countdown is over before calling this function
  return '';
}
