import {
  hoursToSeconds,
  secondsInDay,
  secondsInHour,
  secondsInMinute,
} from 'date-fns';

import { DurationBreakdown } from 'types/duration';

const HOURS_PER_DAY = 24;

export const daysToSeconds = (days: number) => {
  return hoursToSeconds(days * HOURS_PER_DAY);
};

/**
 * Converts our seconds into our custom DurationBreakdown structure
 */
export const mapSecondsToDurationBreakdown = (
  seconds: number
): DurationBreakdown => {
  const days = Math.floor(seconds / secondsInDay);

  const remainingSecondsPerHour = seconds % secondsInDay;
  const remainingSecondsPerMinute = remainingSecondsPerHour % secondsInHour;

  const hours = Math.floor(remainingSecondsPerHour / secondsInHour);
  const minutes = Math.floor(remainingSecondsPerMinute / secondsInMinute);

  return {
    days,
    hours,
    minutes,
  };
};

/**
 * Converts our custom DurationBreakdown structure into seconds
 */
export const mapDurationBreakdownToSeconds = (duration: DurationBreakdown) => {
  const daysInSeconds = duration.days * secondsInDay;
  const hoursInSeconds = duration.hours * secondsInHour;
  const minutesInSeconds = duration.minutes * secondsInMinute;

  return daysInSeconds + hoursInSeconds + minutesInSeconds;
};
